<template>
  <div class="content">
    <Nav></Nav>
    <Navbj></Navbj>
    <div class="cont_inform">
      <div><img src="https://qiniu.fanyu889.com/cxmx/assets/pcimg/9.png" alt="">首页 >  <span>冥想资讯</span></div>
    </div>
    <div class="cont_activity">
      <div class="activity_list">
        <div class="activity_tabs">
          <p v-for="(item,index) in ify_list"  :key="index" @click="ify_btn(index)" :class="ify_xz == index ?'tabs_xz':''">{{item.name}}</p>

        </div>
        <div class="lsit_item" v-for="(item ,index) in lb_list" :key="index" @click="xqtz(item.id)">
           <p>{{item.info}}</p>
           <p>{{item.addtime | formatDateAt}}</p>
           <img :src="item.img" alt="">
        </div>
      </div>
      <div class="activity_paging">
        <p class="paging_top" @click="onClick('prev')" >上一页</p>
        <p class="paging_item" @click="cut(item)" v-for="(item,index) in list" :class="currentPage == item ? 'paging_item_xz':''" :key="index">{{item}}</p>
        <p class="paging_bottom" @click="onClick('next')">下一页</p>
        <p class="paging_end" @click="onClick('last')">最后一页</p>
      </div>
    </div>
    <Btm></Btm>
  </div>
</template>

<script>
import Nav from "@/components/yd-Nav/index"
import Navbj from "@/components/yd-Nav/nav_bj"
import Btm from "@/components/yd-Btmfooter/index"
import {mes_classify,mes_classify_list} from "@/api/request/all";
import {mapState} from "vuex";
export default {
  name: "message",
  data(){
    return{
      total:0,//总长度
      currentPage:1, //选择的页
      pages:1, //当前页
      pagesLength:0, //总共几页 **/5
      list:[],//分页数据
      ify_list:[], //分类
      ify_xz:0, //分类选中
      lb_list:[],//分类列表
      type:''

    }
  },
  components:{
    Nav,
    Navbj,
    Btm
  },
  computed: {
    ...mapState(['user','seo']),
  },
  metaInfo() {
    return {
      title: this.seo[2].title || this.user.indextitle,
      meta: [
        { name: 'description', content: this.seo[2].description ||this.user.drp},
        { name: 'keywords', content:this.seo[2].keywords ||this.user.seo}
      ]
    };
  },
  created() {

  },
  mounted() {
    this.classify();

  },
  methods: {
    //详情跳转
    xqtz(id){
      this.$router.push({
        path:`/1/${id}.html`,
        // name:'/mesdetail',
        // params:{
        //   type:1,
        //   id:id+'.html'
        // }
      })
    },
    //分类
    classify(){
      mes_classify().then((res)=>{
        if(res.status ==1){
          this.ify_list =res.data;
          this.total=res.data[0].count;
          this.pages_fyxh()
          this.type=res.data[0].id;
          this.ify_lb(res.data[0].id)
        }
      })
    },
    //分类请求
    ify_btn(val){
      this.ify_xz=val;
      this.type=this.ify_list[val].id;
      this.total= this.ify_list[val].count;
      this.currentPage=1;
      this.pages=1;
      this.pages_fyxh()
      this.ify_lb(this.ify_list[val].id)
    },
    //分类列表
    ify_lb(id){
      let list ={
        page:this.currentPage,
        pagenum:5,
        type:id
      }
      mes_classify_list(list).then((res)=>{
        if (res.status == 1){
          this.lb_list=res.data;
        }

      })
    },

    cut(vla){
      window.scrollTo({ top: 0, behavior: 'auto' }); // 平滑滚动到顶部
      this.currentPage=vla;
      this.pages=vla;
      let list ={
        page:vla,
        pagenum:5,
        type:this.type,
      }
      mes_classify_list(list).then((res)=>{
        if (res.status == 1){
          this.lb_list=res.data;
        }else {
          this.$message({
            message: '暂无数据',
            type: 'warning',
            offset:200
          });
        }

      })

    },
    //分页加载循环
    pages_fyxh(){
      this.pagesLength= Math.ceil(this.total / 5);
      let array =[];
      for (let i = 1 ; i<=this.pagesLength; i++){
        if (i < this.pages*5+1 && (this.pages-1)*5 < i){
          array.push(i)
        }
      }
      this.list=array;

    },
    //分页点击事件
    onClick(page) {
      console.log(555)
      console.log(this.pages)
      // 这里处理点击事件
      if (page == 'prev' && this.pages > 1) {
        console.log('上一页')
        this.pages--;
        console.log(this.pages)
        this.cut(this.pages);

      } else if (page == 'next' && this.pages < this.pagesLength) {
        console.log('下一页')
        this.pages++;
        this.cut(this.pages);
      } else if (page == 'last') {
        if (this.pages == this.pagesLength ){
          this.$message({
            message: '已经是最后一页了!',
            type: 'warning',
            offset:200
          });
        }else {
          this.pages = this.pagesLength ;
          this.cut(this.pages);
        }
      }else {
        this.$message({
          message: '已经到底了',
          type: 'warning',
          offset:200
        });
      }
    },
  },
}
</script>

<style scoped lang="scss">
.content{
  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  .cont_inform{
    width: 100%;
    height: 1.65rem;
    background: #EEEEEE;
    div{
      width: 100%;
      margin:0 auto;
      height: 1.65rem;
      line-height: 1.65rem;
      img{
        width: 0.53rem;
        height: 0.65rem;
        vertical-align: sub;
        margin-right: 9px;
        margin-left: 0.63rem;
        position: relative;
        top: -0.08rem;
      }
      font-weight: 500;
      font-size: 0.7rem;
      color: #666666;
      span{
        color: #222222;
      }
    }
  }
  .cont_activity{
    width:18rem;
    height: 100%;
    background:#FFFFFF;
    margin: 0 auto;
    padding-top: 1.1rem;

    .activity_list{
      width: 100%;
      margin:0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .activity_tabs{
        width: 100%;
        margin: 0 auto 0.6rem;
        display: flex;
        p{
          width: 4rem;
          height: 1.3rem;
          background: #EEEEEE;
          border-radius: 0.65rem;
          text-align: center;
          line-height: 1.3rem;
          font-weight: bold;
          font-size: 0.7rem;
          color: #666666;
          margin-right: 0.57rem;
        }
        .tabs_xz{
          background: #333333;
          color: #FFFFFF;
        }
      }
      .lsit_item{
        width: 18rem;
        padding:0.75rem 0 ;
        height: 4.2rem;
        border-bottom:1px solid #DADADA ;
        position: relative;
        img{
          width: 5.45rem;
          height: 4.2rem;
          background: #919191;
          border-radius: 0.25rem;
          position: absolute;
          right: 0;
          top: 0.75rem;
        }
        p:nth-child(1){
          width: 11.15rem;
          font-weight: 500;
          font-size: 0.8rem;
          color: #333333;
          position: absolute;
          top: 0.78rem;
          left: 0;

          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          white-space: normal;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p:nth-child(2){
          width: 3.55rem;
          height: 0.48rem;
          font-weight: 500;
          font-size: 0.6rem;
          color: #999999;
          position: absolute;
          bottom: 0.9rem;
          left: 0;
        }

      }
      .lsit_item:hover{
        p:nth-child(2){
          color:#FFA71F ;
        }
        div{
          background: #FFA71F;
          color: white !important;
        }
      }
    }
    .activity_paging{
      width: 18rem;
      margin: 1rem auto 2rem;
      text-align: center;
      display: flex;
      justify-content: center;
      .paging_top{
        width: 3rem;
        height: 1.45rem;
        border-radius: 0.25rem;
        border: 1px solid #DADADA;
        font-weight: 500;
        font-size: 0.65rem;
        color: #666666;
        line-height: 1.45rem;
        margin: 0 5px;
      }
      .paging_bottom{
        width: 3rem;
        height: 1.45rem;
        border-radius: 0.25rem;
        border: 1px solid #DADADA;
        font-weight: 500;
        font-size: 0.65rem;
        color: #666666;
        line-height: 1.45rem;
        margin: 0 5px;
      }
      .paging_end{
        width: 3rem;
        height: 1.45rem;
        border-radius: 0.25rem;
        border: 1px solid #DADADA;
        font-weight: 500;
        font-size: 0.65rem;
        color: #666666;
        line-height: 1.45rem;
        margin: 0 5px;
      }
      .paging_item{
        width: 1.45rem;
        height: 1.45rem;
        border-radius: 4px;
        border: 1px solid #DADADA;
        line-height: 1.45rem;
        font-weight: 500;
        font-size: 0.6rem;
        color: #666666;
        margin: 0 5px;
      }
      .paging_item_xz{
        background: #FFA71F;
        color:#FFFFFF ;
      }
    }
  }
}
</style>